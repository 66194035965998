import { ModuleWithProviders, NgModule, Type } from '@angular/core';

import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { APP_NAVIGATION_DATA_ADAPTER } from './app-navigation-data-adapter.token';
import { AppNavigationDataAdapter } from './app-navigation-data-adapter.interface';
import { AppNavigationEffects } from './app-navigation.effects';
import { appNavigationFeature } from './app-navigation.reducer';

@NgModule({ providers: [provideEffects([AppNavigationEffects]), provideState(appNavigationFeature)] })
export class AppNavigationStateModule {
  static withDataAdapter<DataAdapterClass extends AppNavigationDataAdapter>(
    dataAdapterClass: Type<DataAdapterClass>,
  ): ModuleWithProviders<AppNavigationStateModule> {
    return {
      ngModule: AppNavigationStateModule,
      providers: [{ provide: APP_NAVIGATION_DATA_ADAPTER, useClass: dataAdapterClass }],
    };
  }
}
